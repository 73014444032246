"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

var _ethers = require("ethers");

const useEthers = () => {
  // let provider = null;
  const [provider, setProvider] = (0, _react.useState)(null);
  const [accounts, setAccounts] = (0, _react.useState)([]);
  const [network, setNetwork] = (0, _react.useState)(null);
  (0, _react.useEffect)(() => {
    const updateNetwork = async () => {
      // await window.ethereum.enable();
      const p = new _ethers.ethers.providers.Web3Provider(window.ethereum);
      window.provider = p;
      const [a, {
        chainId,
        name
      }] = await Promise.all([p.listAccounts(), p.getNetwork()]);
      setProvider(p);
      setAccounts(a);
      setNetwork({
        chainId,
        name
      });
    };

    if (window.ethereum) {
      updateNetwork();
      window.ethereum.on('accountsChanged', () => updateNetwork());
      window.ethereum.on('networkChanged', () => updateNetwork());
    }
  }, []);
  return {
    accounts,
    network,
    provider
  };
};

var _default = useEthers;
exports.default = _default;